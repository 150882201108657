.page-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  /*align-items: center;*/
}

.page-layout__content {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  /*max-width: 147rem;*/
  padding-right: 50px;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
}


/*.MuiTableBody-root tr:nth-child(2n) { background: #eee !important;}*/
