.passwordInputDiv{
    width: 100%;
    /* align-content: flex-end; */
    display: block;
    margin-top: 10px;
}
.passwordInputField{
    border:0;
    display: table-cell;
    min-width: 90%; 
    margin-top: 10px;
    padding-left: 10px;
    font-size: 12px;
}
.passwordInputField:focus{
    border-width: 0px;
    border:0px;
    box-shadow: none;
    outline: 0cap;
}
.passwordInputShowButton{
    display: table-cell;
    margin:0
    /* float: right; */
}
.passwordInputSubmitButton
{
    border: 1px solid #4cc1bd;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    color: white;
    background-color: #4cc1bd;
    margin-top: 20px;
    cursor: pointer;
}
.passwordInputSubmitButton:hover{
    background-color: #2db8b3;
}