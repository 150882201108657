.maincontentlayout_container {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
}

.maincontentlayout_sidebar {
    width: 185px;
    flex-shrink: 0;
    overflow-x: hidden;
    box-shadow: 5px 0 0-1.5px rgba(0, 0, 0, 0.1);
    background-color: var(--secondaryColourLight);
    color: var(--textColourSidebar) !important;
}

@media only screen and (max-width: 640px) {
    .maincontentlayout_sidebar {
        display: none;
    }    
}


.sidebar_left_nav{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(76, 193, 189, 0.1);
}

.sidebar_item_text {
    margin-left: 10px;
    margin-right: 10px;
}

.sidebar_item{
    font-size: 1.7rem;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 10px;
}
.sidebar_item:focus * {
    display: block;
}
.sidebar_sub_menu{
    list-style: none;
    padding-left: 15px;
    display: none;
}

.maincontentlayout_main {
    width:100%;
    flex: 1 1 0%;
    height: 100%;
    overflow: hidden;
    margin-left: 50px;
}

@media only screen and (max-width: 640px) {
    .maincontentlayout_main {
        margin-left: 0px;
    }
}