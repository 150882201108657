:root {
  --primaryColour: #4cc1bd;
  --lighthighlightColour: white;
  --chartTitleColour: grey;
  --chartSubTitleColour: grey;
  --chartTickColour: grey;
  --chartTickColour: grey;
  --secondaryColour: #dcf5f3;
  --secondaryColourLight: #dcf5f3;
  --primaryLight: rgb(76, 193, 189)/0.2
  --chartBarTitleColour: grey;
  --detailsTextColour: grey;
  --bodyBGColour: white;
  --headerBGColour: white;
  --chartBGColour: lightgray;
  --textColourGrey:rgb(107 114 128/0.7);
  --textColourSidebar:rgb(107 114 128/1);

  --borderColour:rgb(107 114 128/1);
  
  --componentTextColour: rgb(107 114 128/1);
  --componentTextSize: 1.7vh;
  --defaultTextSize: 12.5px;

  /* call colours */
  --emergency : red;
  --call : rgb(245, 129, 78);
  --sense : #914397;
  --attendance : rgb(148, 202, 102);
  --accessory : #914397;
  --assistance : #F8DA3C;
  --carecall : rgb(225, 21, 131);
  --visit: rgb(1, 87, 62);
  --all: #4cc1bd;

}

.border-radius_site { border-radius: 6px; }


header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
 
html, body {
  /*font-family: "Muli", sans-serif!important;*/
  /*font-family: "Muli" !important;*/
  font-family: sans-serif !important;
  /*font-family:ulp-font,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,sans-serif*/
}
  
.App { text-align: center; }

.header_logo {
  width: 135px;
  padding: 5px;
}

.login_logo {
  width: 47vh;
  height: 18vh;
  padding: 5px;
}

th::first-letter {
    text-transform:capitalize;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  */
  font-size: calc(10px + 2vmin);
  background-color:#4cc1bd; 
  /*
  display: flex;
  flex-direction: row;
  */
  color:  #dcf5f3;
  /*
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  flex: 0 0 53px;
  width: 100%;
  top: 0px;
  */

}

.App-link { color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg_emergency { background-color: var(--emergency); color: var(--headerBGColour);}
.bg_call { background-color: var(--call); color: var(--headerBGColour); }
.bg_assistance { background-color: var(--assistance); color: var(--headerBGColour); }
.bg_attendance { background-color: var(--attendance); color: var(--headerBGColour); }
.bg_sense, .bg_accessory { background-color: var(--accessory); color: var(--headerBGColour); }
.bg_carecall { background-color: var(--carecall); color: var(--headerBGColour); }
.bg_all { background-color: var(--all); color: var(--headerBGColour); }
.colour-white{ color: white}

img.live_dashboard_call_type { width: 30px; height: 30px; }
img.live_dashboard_call_type_img { width: 35px; height: 40px; }

img.sense_dashboard { width: 50px; height: 37px; }
img.heartbeat { width: 25px; height: 25px; }

/* DASHBOARD CALLS */
.dashboard_calls_header {
  /*width: 100%;*/
  color: white;
  display: grid;
  grid-template-columns: 42px 130px 110px 60px;
  grid-gap: 5px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dashboard_calls_header div {
  text-align: center;
  font-size: 2.4vh;
  align-self: center;
}

/* LIVE CALLS */
.live_call_header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.live_call_header_container div {
  text-align: left;
  font-size: 14px;
}

.call_body_field_header {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 5px;
}

.call_body_field_header div {color: white;}

.live_call_header_container div { color: white; }

/* SENSE CALLS */
.sense_call_header_container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 5px;
  /*width: 100%;*/
}

.sense_call_header_container div { color: white;  font-size: var(--defaultTextSize); }

.dashboard_frame { 
  height: 80%;
  overflow-y: scroll;
  /* padding-right: 8px; */
}

.left-padding-10 {padding-left: 10px;}
.align-left { text-align: left; }

.upper_case { text-transform: uppercase; }

.davy_monitor {
  display: grid;
  grid-template-columns: 150px 150px 350px 150px;
  grid-gap: 5px;
  width: 100%;
}

/*
.bg_sense_fall { background-color: #ED0B44; }
.bg_sense_manOnGround { background-color: #DA1654; }
.bg_sense_bathroom { background-color: #C82265; }
.bg_sense_chair { background-color: #B62D76; }
.bg_sense_bed { background-color: #A33886; }
.bg_sense_room { background-color: #914397; }
*/

.bg_sense_fall { background-color: red;; }
.bg_sense_manOnGround { background-color: red; }
.bg_sense_bathroom { background-color: #F8DA3C; }
.bg_sense_chair { background-color: #914397; }
.bg_sense_bed { background-color: #914397; }
.bg_sense_room { background-color: #914397; }

.header_logo_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3.5vh;
  font-weight: 500;
}
.header_logo_container div {
  font-weight: 100;
  font-size: 3vh;
  text-transform: uppercase;
  font-weight: 100;
  top: 3px;
  position: relative;
}

.live_call_container { 
  border-radius: 6px;
  padding: 5px;
  overflow: visible; 
  margin-bottom: 11px;
}

.uppercasefirst::first-letter { text-transform: uppercase; }


aside.left a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 2.6vh;
  color: #818181;
  display: block;
}

aside.left a:hover { color: #f1f1f1; }


@import url("./css/buttons/button_3.css");
@import url("./css/display/flex.css");
@import url("./css/display/dashboard_container.css");
@import url("./css/display/structure.css");
@import url("./css/display/position.css");
@import url("./css/display/grid.css");
@import url("./css/pages/integration.css");
@import url("./css/components/card.css");
@import url("./css/pages/main_content_layout.css");
@import url("./styles/forms.css");
@import url("./styles/default.css");
@import url("./css/forms/aq_form.css");


.todays_calls_tile {
    padding: 5px;
}

.support_feedback {
  padding-left: 12px;
}

.marginLeft17 { margin-left: 27px; }

.floatRight { float: right; }

.marginTopBottom5 {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.tableFont{
  font-size: 1.3rem !important;
}

.alignSelfCenter { align-self: center; }

.form_container {
  color: var(--textColourSidebar) !important;
  font-size: 1.8vh;
}

.width500 { width: 500px; }



.view_details_link{
  cursor: pointer;
  color: blue;
}

.strikethrough-text {
  text-decoration: line-through;
  color: red;
}

.zone_profile_zone_summary_container{
  display: flex;
  flex-direction: row;
}

.aqDefaultText{
  font-size: var(--defaultTextSize);
}


.main * {
  overflow: visible !important;
  font-size: var(--defaultTextSize) !important;
}

.y_scroll_auto{ overflow-y: auto; }

.validate_close_div{
  align-self: flex-end;
}
.validate_button{
  border: solid var(--textColourSidebar) 1px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    background-color:#D0F5F6;
    border: solid 1px #4CC1BD;
    border-radius: 6px;
}
.validate_button:disabled, .validate_button[disabled]{
  background-color: #f2c7bf;
  border:solid 1px #F8A392;
}
.close_buton{
  border: solid 1px black;
  background-color:#E6E6E6;
  font-size: var(--defaultTextSize);
  border: solid var(--textColourSidebar) 1px;
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: black;
  border-radius: 6px;
}